<template>
    <v-menu
        offset-y
        style="top: 38px;"
        class="col-sm-12"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <v-btn icon rounded class="pa-0">
                    <v-badge
                        v-if="notifications.unread.length > 0"
                        :content="notifications.unread.length"
                        overlap

                    >

                        <!--v-avatar size="24">
                            <v-img>awoie</v-img>
                        </v-avatar-->
                        <v-icon>mdi-bell</v-icon>
                    </v-badge>
                    <v-icon v-else>mdi-bell</v-icon>
                </v-btn>
            </div>
        </template>
        <v-card>
            <v-toolbar dense elevation="0">
                <v-toolbar-title class="pl-0 darken-4 v-subheader font-weight-bold ">Notifikationer ({{notifications.unread.length}})
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu>
                    <template v-slot:activator="{on,attr}">
                        <v-btn icon>
                            <v-icon
                                v-bind="attr"
                                v-on="on"
                            >mdi-dots-horizontal
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list-item @click="markAllRead()" link>
                            <v-list-item-content class="caption">
                                Markér alle læst
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-menu>
            </v-toolbar>
            <v-divider/>
            <div v-if="notifications.unread.length >= 1">
                <v-card-subtitle class="font-weight-bold pb-0 pt-2 caption text-uppercase">Nye</v-card-subtitle>
                <v-virtual-scroll
                    :items="notifications.unread"
                    :item-height="56"
                    max-height="calc(90vh - 194px)"
                    min-height="56"
                    width="360px"
                    max-width="100vw"
                    bench="10"
                >
                    <template v-slot="{item}">
                        <v-list-item
                            class="notificationListItem notificationListItemRead flex "
                            elevation="0"
                            link
                        >
                            <v-list-item-avatar color="indigo" size="40">
                                <span class="white--text">36</span>
                            </v-list-item-avatar>

                            <v-list-item-content class="pt-0 pb-0">
                                <v-list-item-title class="subtitle-2" color=" grey darken-1">
                                    {{item.title}}
                                    <br>
                                    <span class="text-truncate subtitle-2 font-weight-regular">asfasf</span>
                                    <v-list-item-subtitle
                                        class="caption blue--text text--darken-1 text-truncate float-right">
                                        2 timer siden
                                    </v-list-item-subtitle>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
            </div>
            <v-divider/>
            <div v-if="notifications.read.length >= 1">
                <v-card-subtitle class="font-weight-bold pb-0 pt-2 caption text-uppercase">Sete</v-card-subtitle>
                <v-virtual-scroll
                    :items="notifications.read"
                    :item-height="56"
                    max-height="calc(90vh - 194px)"
                    min-height="56"
                    width="360px"
                    max-width="100vw"
                    bench="10"
                >
                    <template v-slot="{item}">
                        <v-list-item
                            class="notificationListItem notificationListItemRead flex "
                            elevation="0"
                            link
                        >
                            <v-list-item-avatar color="indigo" size="40">
                                <span class="white--text">36</span>
                            </v-list-item-avatar>

                            <v-list-item-content class="pt-0 pb-0">
                                <v-list-item-title class="subtitle-2" color=" grey darken-1">
                                    {{item.title}}
                                    <br>
                                    <span class="text-truncate subtitle-2 font-weight-regular">{{item.reference}}</span>
                                    <v-list-item-subtitle
                                        class="caption blue--text text--darken-1 text-truncate float-right">
                                        2 timer
                                    </v-list-item-subtitle>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
            </div>
            <v-divider></v-divider>
            <v-footer class="text-center">
                <v-spacer/>
                <router-link :to="{name:'Oversigt'}">Se flere</router-link>
                <v-spacer/>
            </v-footer>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: "notificationBadge",
        data() {
            return {
                notifications: {
                    count: 2,
                    unread: [
                        {title: 'Møde', date: '21-01-1986'},
                        {title: 'Møde', date: '21-01-1986'},
                        {title: 'Møde', date: '21-01-1986'},
                    ],
                    read: [
                        {title: 'Møde', date: '21-01-1986'},
                        {title: 'Møde', date: '21-01-1986'},
                        {title: 'Møde', date: '21-01-1986'},
                    ],

                },
                unreadCount: 1,

            }
        },
        methods: {
            markAllRead() {
                console.log('marked read')
            },

        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>