export default {
    namespaced:true,
    methods: {
        daysFromNow(days) {
            let fn = new Date();
            fn.setDate(fn.getDate() + days);
            let month = (fn.getMonth() + 1).toString().length === 1 ? '0' + (fn.getMonth() + 1) : (fn.getMonth() + 1)
            let day = fn.getDate().toString().length === 1 ? '0' + fn.getDate() : fn.getDate()
            return fn.getFullYear() + '-' + month + '-' + fn.getDate()
        }
    }
}