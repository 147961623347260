<template>
    <div>
        <v-btn
            @click="$store.state.dataTables.breakPoint = 6000"
            class="mr-2"
            color="transparent"
            elevation="0"
            fab
            x-small
            style="background-color:rgba(0,0,0,0) !IMPORTANT;"
            v-if="$store.state.dataTables.breakPoint === 0"
        >
            <v-icon>mdi-view-headline</v-icon>
        </v-btn>
        <v-btn
            @click="$store.state.dataTables.breakPoint = 0"
            class="mr-2"
            color="transparent"
            style="background-color:rgba(0,0,0,0) !IMPORTANT;"
            elevation="0"
            fab
            x-small
            v-else
        >
            <v-icon>mdi-view-stream</v-icon>
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "tableView"
    }
</script>
